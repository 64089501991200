export const urlParamsBuilder = (payload: { [key: string]: unknown }): string => {
    const params = new URLSearchParams();
    Object.entries(payload).map(([key, value]) => {
        const isString = typeof value === 'string' && Boolean(value);
        if (isString) {
            params.append(key, value);
            return;
        }

        const isNumber = typeof value === 'number' && Boolean(value);
        if (isNumber) {
            params.append(key, value.toString());
            return;
        }

        const isArray = Array.isArray(value) && value.length > 0;
        if (isArray) {
            value.map((v) => params.append(key, v));
            return;
        }

        const isObject =
            value !== null &&
            typeof value === 'object' &&
            !Array.isArray(value) &&
            !Object.values(value).some((v) => v === null);
        if (isObject) {
            params.append(key, JSON.stringify(value));
            return;
        }
    });
    return params.toString();
};
